import PropTypes from 'prop-types'
import React from 'react'
import './bucket.css'

function bucketImage (image, alt) {
  return <img src={image.publicURL} className="img-fluid bucket-static" alt={alt} />;
}

function bucketAnimation (image, alt) {
	return <img src={image.publicURL} className="img-fluid bucket-animation" alt="" />
}

const Bucket = ({ title, images, tag, alt, anim }) => {
  const Tag = tag;
  const imageBase = (images[0] !== null ? images[0] : ``);
  let imageHover = (images[1] !== null ? images[1] : ``);

	if (anim === false) {
		imageHover = ``;
	}
	return(
		<div className="bucket-content">
		  {imageBase &&
			<div className="bucket-icon">
          {imageBase.localFile && bucketImage(imageBase.localFile, alt)}
					{imageHover.localFile && bucketAnimation(imageHover.localFile, alt)}
			</div>
		  }
      <Tag className="bucket-title">{title}</Tag>
    </div>
    )
  }

Bucket.propTypes = {
  title: PropTypes.string,
  images: PropTypes.array,
  tag: PropTypes.string,
  alt: PropTypes.string,
  anim: PropTypes.bool,
}

Bucket.defaultProps = {
  title: '',
  images: [],
  tag: 'p',
  alt: '',
  anim: false,
}

export default Bucket
