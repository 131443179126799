import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ReactHtmlParser from "react-html-parser"
import Video from '../components/video/video'
import Grid from '../components/grid'
import GridCell from '../components/gridCell'
import Bucket from '../components/bucket'
import ugLogo from '../images/UofG_Cornerstone_rgb.png'
import { Link, graphql } from 'gatsby'

function isEven(n) {
  return n % 2 === 0;
}

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.onShowOverlay = this.onShowOverlay.bind(this);
    this.videoRef = React.createRef();

    this.state = {
      isOverlayOpen: false
    }
  }
  onShowOverlay() {
    // Allow search overlay to trigger video pause
    this.videoRef.current.handleVideoPause();
    this.setState({ isOverlayOpen: !this.state.isOverlayOpen });
  }
  render() {
    const { data } = this.props;
    const buckets = data.allTaxonomyTermAnnualReport.edges
    let checkColours = ['colourB','colourA']
    const video = data.video.edges[0].node
    const intro = data.intro.edges[0].node
    let videoPath = ``;
    let videoTranscript = ``;
    let introText = ``;

    if(video) {
      videoPath = video.url.path;
      videoTranscript = video.relationships.transcriptFile ? video.relationships.transcriptFile.localFile.publicURL : ``;
    }

    if(intro){
      introText = new ReactHtmlParser(intro.body.processed);
    }

    return(
      <Layout onShowOverlay={this.onShowOverlay}>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <div className="home-page page-container">
          <div className="primary">
            <div id="homevideo">
                { videoPath &&
                    <Video
                      ref={this.videoRef}
                      title={video.title}
                      url={videoPath}
                      transcriptFile={videoTranscript}
                      loop={video.loop}
                      autoplay={video.autoplay}
                      handleVideoPause={this.onShowOverlay}
                      isOverlayOpen={this.state.isOverlayOpen}
                    />
                }
            </div>
            <div id="intro">
              <a href="https://www.uoguelph.ca">
                <img id="UoGlogo" className="img-fluid" alt="University of Guelph Home" src={ugLogo} />
              </a>
              { introText }
              {/* <p>The University of Guelph's Computing and Communications Services aims to Improve Life for the University community through technology solutions. We are partners, connectors, enablers and protectors. <a href="mailto:58888help@uoguelph.ca">Contact us</a> today to see how we can help!</p> */}
            </div>
          </div>
          <div className="secondary">
            {/* Bucket Grid */}
            <Grid classNames="bucket-grid">
                {buckets.map((bucket, index)  => {
                let bucketImages = (bucket.node.relationships.field_icon !== null ? bucket.node.relationships.field_icon : [])

                if(isEven(index)) {
                    checkColours.reverse()
                }
                return(
                    <GridCell key={index} classNames={`bucket-grid-cell ${checkColours[isEven(index) ? 0 : 1]}`}>
                      <Link to={bucket.node.path.alias} className="bucket">
                        <Bucket title={bucket.node.name} images={bucketImages} anim={true} />
                      </Link>
                    </GridCell>
                    )
                })}
            </Grid>
          </div>
        </div>

      </Layout>
    )
  }
}

export default IndexPage

export const query = graphql`
query {

  intro:allNodePage(
    limit: 1
    filter: {promote: {eq:true}}) {
    edges{
      node{
        body {
          value
          processed
        }
      }
    }
  }

  allTaxonomyTermAnnualReport (
    sort:{fields:[weight]}
  ) {
    edges {
      node {
        drupal_internal__tid
        name
        path {
          alias
        }
        relationships{
          field_icon{
            id
            drupal_internal__fid
            filename
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 200, maxHeight: 200) {
                ...GatsbyImageSharpFluid
                }
                fixed(width: 100, height: 100) {
                  base64
                  tracedSVG
                  aspectRatio
                  width
                  height
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  originalName
                }
              }
            }
          }
        }
      }
    }
  }
  video:allNodeVideo(limit:1){
    edges {
      node {
        title
        url:field_video_url {
          path:uri
        }
        relationships{
          transcriptFile:field_transcript_file_download{
            localFile{
              publicURL
            }
          }
        }
        loop:field_video_loop
        autoplay:field_video_autoplay
      }
    }
  }
}
`