import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from './header'
import './layout.css'

const Layout = ({ children, onShowOverlay }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            year
            organization
          }
        }
      }
    `}
    render={data => (
      <>
      <nav aria-label="Skip links">
        <a className="sr-only sr-only-focusable skiplink" href="#content">Skip to main content</a>
      </nav>
      <div className="wrapper-container">
        <Header
          onShowOverlay={onShowOverlay}
          siteTitle={data.site.siteMetadata.title} 
          siteYear={data.site.siteMetadata.year} 
          siteOrganization={data.site.siteMetadata.organization} 
        />
        <main id="content" className="content">
          {children}
        </main>
      </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  onShowOverlay: PropTypes.func,
}

export default Layout
