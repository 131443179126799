import PropTypes from 'prop-types';
import React from 'react';
import Search from './search'
import Menu from './menu';
import { Link } from 'gatsby'
import { Navbar, NavbarBrand } from 'reactstrap';
import './header.css'

const Header = ({ siteTitle, siteYear, siteOrganization, onShowOverlay }) => (
  <header className="header" aria-label="Primary">
    <div className="title-container">
      <Navbar color="faded" light aria-label="Primary">
        <h1 className="title mr-auto" aria-describedby="site-subtitle">
          <NavbarBrand to="/" tag={Link}>
            {siteTitle} {siteYear}
          </NavbarBrand>
        </h1>
        <Search onShowOverlay={onShowOverlay} />
        <Menu />
      </Navbar>
    </div>
    <div className="subtitle-container">
        <p id="site-subtitle" className="subtitle">{siteOrganization}</p>		
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteYear: PropTypes.string,
  siteOrganization: PropTypes.string,
  onShowOverlay: PropTypes.func,
}

Header.defaultProps = {
  siteTitle: `<Site Title>`,
  siteYear: `<Year>`,
  siteOrganization: `<Site Organization>`,
  onShowOverlay: null,
}

export default Header
