import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Collapse, NavbarToggler, Nav, NavItem, NavLink } from 'reactstrap'
import {Link} from 'gatsby'
import './menu.css'

class Menu extends React.Component {
	constructor(props) {
		super(props);
		this.toggleNavbar = this.toggleNavbar.bind(this);
		this.state = {
			collapsed: true,
			ariaExpanded: false,
		};
	}
	toggleNavbar() {
		this.setState({
			collapsed: !this.state.collapsed,
			ariaExpanded: !this.state.ariaExpanded,
		});
	}
    
	render() {
		return (
			<React.Fragment>
				<NavbarToggler onClick={this.toggleNavbar} className="mr-2" aria-expanded={this.state.ariaExpanded}>
					<span className="navbar-toggler-icon"><span className="sr-only">Toggle main menu</span></span>
				</NavbarToggler>
				<Collapse isOpen={!this.state.collapsed} navbar>
					<Nav navbar>
						{this.props.navlinks.edges.map(({ node }) => (
							<NavItem key={node.drupal_internal__tid}>
								<NavLink tag={Link} to={node.path.alias}>{node.name}</NavLink>
							</NavItem>
						))}			
					</Nav>
				</Collapse>
			</React.Fragment>
		);
	}
}
 
export default () => (
	<StaticQuery
		query = { graphql`
			query {
				allTaxonomyTermAnnualReport (
					sort:{fields:[weight]}
				  ){
					edges {
						node {
							drupal_internal__tid
							path {
								alias
							}
							name
						}
					}
				}
			}
		`}
		render={(data) => (
			<Menu navlinks={data.allTaxonomyTermAnnualReport} />

		)}
	/>
)

