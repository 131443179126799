import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import { UncontrolledTooltip } from 'reactstrap'
import { MdPlayArrow, MdPause, MdReplay, MdDescription } from 'react-icons/md'
import './video.css'

/* 

Example Usage:

<Video
  title={video.title}                       [string]
  url={video.url.path}                      [string]
  transcriptFile={video.transcriptFile}     [string]
  loop={video.loop}                         [bool]
  autoplay={video.autoplay}                  [bool]
/>

*/

class Video extends Component {
  state = {
    toolTipMsg: 'Play',
    playing: false,
    loop: false,
    autoplay: false,
    ended: false,
    firstLoad: true,
    isOverlayOpen: false,
    videoRendered: false,
  }
  load = url => {
    this.setState({
      url
    })
  }
  renderVideoStatus = () => {
    return(
      <div id="video-state" tabIndex="-1" className="sr-only" aria-live="assertive" aria-atomic="true">
      { this.state.playing ? 
        <span>Video playing</span>
        : (!this.state.ended ? 
          <span>Video paused</span>
          : <span>Video ended</span>)
        }
    </div>
    )
  }
  onReady = () => {
    console.log("onReady")
  }
  handleVideoPause = () => {
    console.log("handleVideoPause")
    this.setState({playing:false, ended: false, toolTipMsg: 'Play'})
  }
  playPause = () => {
    this.setState({ playing: !this.state.playing })
  }
  onPlay = () => {
    console.log('onPlay')
    this.setState({ playing: true, ended: false, toolTipMsg: 'Pause'})

    // Immediately pause video if autoplay is turned off
    if(this.state.firstLoad === true){
      this.setState({videoRendered: true});
      // console.log("video rendered")
      if (this.props.autoplay === false || this.props.isOverlayOpen === true){
        this.setState({playing:false, ended: false, toolTipMsg: 'Play', firstLoad: false})
      }
    }
  }
  onPause = () => {
    console.log('onPause')
    this.setState({ playing: false, toolTipMsg: 'Play' })
  }
  onEnded = () => {
    this.setState({ ended: true, toolTipMsg: 'Replay' })
    console.log('onEnded')
  }
  ref = player => {
    this.player = player
  } 
  render () {
    const { playing } = this.state
   
    // Avoid tabbing into iframe and remove deprecated frameborder attribute
    if (typeof document !== `undefined`) {
      const videoiframe = document.getElementsByTagName("iframe");
      if(videoiframe.length > 0){
        document.getElementsByTagName("iframe")[0].tabIndex = -1;
        document.getElementsByTagName("iframe")[0].removeAttribute('frameBorder');
      }
    }

    return (
      <div className='video-wrapper' aria-label={this.props.title}>
        <ReactPlayer
          url={this.props.url}
          config={{
            vimeo: {
              playerOptions: {
                background: true
              }
            }
          }}
          width='100%'
          height='auto'
          className='react-player embed-responsive embed-responsive-16by9'
          loop={this.props.loop}
          ref={this.ref}
          playing={playing}
          onPlay={this.onPlay}
          onPause={this.onPause}
          onEnded={this.onEnded}
        />   

        {this.state.videoRendered && this.renderVideoStatus()}

        <div className={`video-controls ${this.state.ended ? `ended` : `` }`} >
          <button className="btn-play" onClick={this.playPause} id="video">
            {
              playing ? 
              <span>
                <span className="sr-only">Pause video</span>
                <MdPause />
              </span>
              : 
              (
                !this.state.ended ?
                <span>
                  <span className="sr-only">Play video</span>
                  <MdPlayArrow />
                </span>
                : 
                <span><span className="sr-only">Replay video</span><MdReplay /></span>
              )
            }
          </button>
          <UncontrolledTooltip placement="top" target="video">
            { this.state.toolTipMsg }
          </UncontrolledTooltip>

          {this.props.transcriptFile &&
            <a id="downloadTranscript" href={ this.props.transcriptFile }>
              <MdDescription />
              <span className="sr-only">Download { this.props.title } Transcript</span>
              <UncontrolledTooltip placement="top" target="downloadTranscript">
                Download { this.props.title } Transcript
              </UncontrolledTooltip>
            </a>
          }

        </div>
      </div>
    )
  }
}

Video.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  transcriptFile: PropTypes.string,
  loop: PropTypes.bool,
}
Video.defaultProps = {
  url: `https://vimeo.com/235408320`,
  title: `CCS video`, // default UG video title
  transcriptFile:``,
  loop: false,
}

export default Video