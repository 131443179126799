import React from 'react'
import { DialogOverlay, DialogContent } from "@reach/dialog";
import { StaticQuery, graphql } from 'gatsby'
import { FiSearch, FiXSquare } from 'react-icons/fi';
import { UncontrolledTooltip } from 'reactstrap';
import SearchSite from './utils/search-utility'
import './search.css'
import './closeButton.css'

class Search extends React.Component {
    constructor(props) {
        super(props)
        this.initialFocusElement = React.createRef();
        this.state = {
            showDialog: false,
        }
        this.onShowOverlay = this.onShowOverlay.bind(this);
    }
    onShowOverlay(e) {
        this.setState({ showDialog: true });
        // Inform parent components that overlay is being shown
        if(this.props.onShowOverlay !== null){
            this.props.onShowOverlay(e.target.value);
        }
    }

    render () {
        return (
            <>
                <button id="search" className="search-btn" onClick={this.onShowOverlay} >
                    <span className="sr-only">Search</span>
                    <FiSearch />
                </button>
                <UncontrolledTooltip placement="bottom" target="search">
                    Search
                </UncontrolledTooltip>

                <DialogOverlay 
                        isOpen={this.state.showDialog} 
                        onDismiss={() => this.setState({ showDialog: false })} 
                        initialFocusRef={this.initialFocusElement} >
                    <DialogContent>
                        <div id="search-overlay" className="overlay" aria-labelledby="search-overlay-label">
                            <div className="container">
                                <button id="search-close" className="closebtn" onClick={() => this.setState({ showDialog: false })} >
                                    <FiXSquare /> <span className="sr-only">Close search overlay</span>
                                </button>
                                <div className="wrap">
                                    <StaticQuery
                                        query={graphql`
                                            query SearchIndexQuery {
                                                siteSearchIndex {
                                                    index
                                                }
                                            }
                                        `}
                                        render={data => (
                                            <SearchSite searchInputRef={this.initialFocusElement} searchIndex={data.siteSearchIndex.index} />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </DialogOverlay>

            </>
        )
    }
}

export default Search